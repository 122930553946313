import { z } from "zod";
import { SecurityCapabilityFeaturesEnumSchema } from "../cloudAccountsCas/schemas";
import { StatusSchema } from "../permissionStatus";

export const CloudAccountStatusComponentSchema = z.object({
  name: z.string(),
  message: z.string(),
  remediation: z.string(),
  status: StatusSchema,
});

const featureState = ["disabled", "enabled"] as const;
const featureStateEnum = z.enum(featureState);
export type FeatureStateType = z.infer<typeof featureStateEnum>;

export const features = z.object({
  name: SecurityCapabilityFeaturesEnumSchema,
  state: z.enum(featureState),
});

const StatusComponentSchema = z
  .object({
    subComponents: z.array(CloudAccountStatusComponentSchema).optional(),
  })
  .merge(CloudAccountStatusComponentSchema);

export type CloudStatusComponent = z.infer<typeof StatusComponentSchema>;

export const CloudAccountStatusResponseSchema = z.array(StatusComponentSchema);

export const IbmRequestPayload = z.object({
  accountId: z.string(),
  accountType: z.string(),
  apiKey: z.string(),
  name: z.string(),
  svcIdIamId: z.string(),
  enabled: z.boolean(),
  groupIds: z.string().array(),
});

export type IbmRequestPayloadSchema = z.infer<typeof IbmRequestPayload>;

export const OnboardingStatusResponse = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    status: StatusSchema,
    statusMessage: z.object({
      message: z.string(),
      staticMessage: z.boolean(),
    }),
  }),
);

export const IbmStatusResponse = z.object({
  status: OnboardingStatusResponse,
});

export type IbmStatusResponseSchema = z.infer<typeof IbmStatusResponse>;
export type OnboardingStatusResponseSchema = z.infer<
  typeof OnboardingStatusResponse
>;

const hierarchySelection = z.object({
  displayName: z.string(),
  nodeType: z.string(),
  resourceId: z.string(),
  selectionType: z.string(),
});

export const AwsRequestPayload = z.object({
  accountId: z.string(),
  accountType: z.string(),
  name: z.string(),
  enabled: z.boolean(),
  roleArn: z.string(),
  features: z.array(features),
  groupIds: z.array(z.string()).optional(),
  defaultAccountGroupId: z.string().optional(),
  hierarchySelection: z.array(hierarchySelection).optional(),
  storageUUID: z.string().optional(),
  storageScanConfig: z
    .object({
      buckets: z.object({
        backward: z.array(z.string()),
        forward: z.array(z.string()),
      }),
      scanOption: z.string(),
      snsTopicArn: z.string(),
    })
    .optional(),
  customMemberRoleNameEnabled: z.boolean().optional(),
  memberRoleName: z.string().optional(),
  skipOverrideMemberRoleName: z.boolean().optional(),
  useTenantExternalId: z.boolean().optional(),
  unifiedCftDisabled: z.boolean().optional(),
  automatedFlow: z.boolean().optional(),
});

export const AlibabaRequestPayload = z.object({
  ramArn: z.string(),
  name: z.string(),
  enabled: z.boolean(),
  vulnerabilityAssessmentEnabled: z.boolean(),
  groupIds: z.string().array(),
  accountId: z.string(),
  deploymentType: z.string().optional(),
});

export type AlibabaRequestPayloadSchema = z.infer<typeof AlibabaRequestPayload>;

export const OciRequestPayload = z.object({
  accountId: z.string(),
  accountType: z.string(),
  defaultAccountGroupId: z.string(),
  enabled: z.boolean(),
  groupName: z.string(),
  homeRegion: z.string(),
  policyName: z.string(),
  userName: z.string(),
  userOcid: z.string(),
  name: z.string(),
});

export type OciRequestPayloadSchema = z.infer<typeof OciRequestPayload>;

export const ReviewStatusResponse = z.object({
  status: z
    .object({
      buckets: z.object({
        backward: z.array(z.string()),
        forward: z.array(z.string()),
      }),
      scanOption: z.string(),
      snsTopicArn: z.string(),
    })
    .optional(),
});

export type AwsRequestPayloadType = z.infer<typeof AwsRequestPayload>;

export type AwsStatusResponseType = z.infer<typeof OnboardingStatusResponse>;
export type SecurityCapabilitiesFeatures = z.infer<typeof features>;
export type ReviewStatusResponseSchema = z.infer<typeof ReviewStatusResponse>;

export const AzureRequestPayload = z.object({
  clientId: z.string(),
  cloudAccount: z.object({
    accountId: z.string().optional(),
    accountType: z.string(),
    enabled: z.boolean(),
    name: z.string(),
    groupIds: z.array(z.string()).optional(),
  }),
  environmentType: z.string(),
  key: z.string().optional(),
  monitorFlowLogs: z.boolean(),
  servicePrincipalId: z.string(),
  tenantId: z.string(),
  features: z.array(features),
  hierarchySelection: z.array(hierarchySelection).optional(),
  defaultAccountGroupId: z.string().optional(),
  rootSyncEnabled: z.boolean().optional(),
  accountGroupMode: z.string().optional(),
  authMode: z.string().optional(),
});

export type AzureRequestPayloadType = z.infer<typeof AzureRequestPayload>;

export type AzureStatusResponseType = z.infer<typeof OnboardingStatusResponse>;

export type HierarchySelectionType = z.infer<typeof hierarchySelection>;

export const GcpRequestPayload = z.object({
  accountGroupCreationMode: z.string().optional(),
  cloudAccount: z.object({
    accountId: z.string(),
    accountType: z.string(),
    enabled: z.boolean(),
    groupIds: z.array(z.string()).optional(),
    name: z.string(),
    projectId: z.string(),
  }),
  authMode: z.string(),
  compressionEnabled: z.boolean(),
  Credential: z.any(),
  dataflowEnabledProject: z.string(),
  features: z.array(features),
  defaultAccountGroupId: z.string().optional(),
  flowLogStorageBucket: z.string(),
  hierarchySelection: z.array(hierarchySelection).optional(),
});

export type GcpRequestPayloadType = z.infer<typeof GcpRequestPayload>;

export type GcpStatusResponseType = z.infer<typeof OnboardingStatusResponse>;
