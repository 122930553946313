import { LoadingIcon } from "icons";
import { hashParams } from "prisma";
import { lazy } from "react";
import { getPreferences, preferencesKeys, useTokenStore } from "requests";
import {
  createRouter,
  privateRootRoute,
  publicRootRoute,
  rootRoute,
} from "router";
import { signinRedirectLocationStorageKey } from "stores";
import { queryClient } from "./queryClient";

import { commandCenterRoute } from "command-center";
import { templateRoute } from "template";
import { CatchAll } from "./pages/CatchAll/CatchAll";
import { catchAllRoute } from "./pages/CatchAll/catchAllRoute";
import {
  actionPlansIndexRoute,
  actionPlansLayoutRoute,
} from "./pages/actionPlans/actionPlansLayoutRoute";
import { actionPlansOverviewRoute } from "./pages/actionPlans/actionPlansOverviewRoute";
import { adoptionAdvisorRoute } from "./pages/adoptionAdvisor/adoptionAdvisorRoute";
import {
  alertsCatchAllRoute,
  alertsIndexRoute,
  alertsLayoutRoute,
} from "./pages/alerts/alertsLayoutRoute";
import { alertsOverviewRoute } from "./pages/alerts/alertsOverviewRoute";
import { policyWizardInAlertsRoute } from "./pages/alerts/policyWizardInAlertsRoute";
import { alertsL2TableRoute } from "./pages/alerts/tablesView/AlertsL2TableRoute";
import { casProjectsRedirectRoute } from "./pages/code/casProjectsRedirectRoute";
import {
  casCicdRisksNestedRoute,
  casCicdRisksRoute,
  casProjectsNestedRoute,
  casProjectsRoute,
  casRepositoriesNestedRoute,
  casRepositoriesRoute,
  casSbomNestedRoute,
  casSbomRoute,
  casTechnologiesNestedRoute,
  casTechnologiesRoute,
  homeAppSecIndexRoute,
  homeAppSecLayoutRoute,
} from "./pages/code/codeRoute";
import {
  complianceCatchAllRoute,
  complianceLayoutRoute,
} from "./pages/compliance/complianceLayoutRoute";
import { complianceStandardsLayoutRoute } from "./pages/compliance/complianceStandardsLayoutRoute";
import {
  complianceRequirementsLayoutRoute,
  complianceRequirementsRoute,
} from "./pages/compliance/requirements/complianceRequirementsRoute";
import { complianceSectionsRoute } from "./pages/compliance/sections/complianceSectionsRoute";
import { complianceStandardsRoute } from "./pages/compliance/standards/complianceStandardsRoute";
import { computeLayoutRoute } from "./pages/compute/computeLayoutRoute";
import { assetListSidecarRoute } from "./pages/dashboard/charts/asm/UnmanagedAssets/AssetListSideCar/assetListSidecarRoute";
import { vulnerabilitiesSidecarRoute } from "./pages/dashboard/charts/vulnerabilities/TopPrioritizedVulnerabilities/vulnerabilitiesSidecarRoute";
import { dashboardRoute } from "./pages/dashboard/dashboardRoute";
import { dashboardsLayoutRoute } from "./pages/dashboard/dashboardsLayoutRoute";
import { dashboardsRedirectRoute } from "./pages/dashboard/dashboardsRedirectRoute";
import {
  homeDataAiInventoryRoute,
  homeDataAlertsNestedRoutes,
  homeDataAlertsRoute,
  homeDataComplianceRoute,
  homeDataFilesRoute,
  homeDataFindingsNestedRoutes,
  homeDataFindingsRoute,
  homeDataInventoryNestedRoutes,
  homeDataInventoryRoute,
  homeDataLayoutRoute,
  homeDataOverviewNestedRoutes,
  homeDataOverviewRoute,
  homeDataPoliciesRoute,
  homeDataReportsRoute,
  homeDataRisksNestedRoutes,
  homeDataRisksRoute,
  homeDataSettingsNestedRoutes,
  homeDataSettingsRoute,
} from "./pages/data/homeDataLayoutRoute";
import { fttvExperienceRoute } from "./pages/fttvExperience/fttvExperienceLayout";
import { ftuExperienceRoute } from "./pages/ftuExperience/ftuExperienceLayout";
import { editPolicyWizardBackwardCompatibleRoute } from "./pages/governance/components/PolicyWizard/editPolicyWizardBackwardCompatibleRoute";
import { policyWizardRoute } from "./pages/governance/components/PolicyWizard/policyWizardRoute";
import { governanceRoute } from "./pages/governance/governanceRoute";
import {
  homeCloudIndexRoute,
  homeCloudLayoutRoute,
} from "./pages/home/cloud/homeCloudLayoutRoute";
import { welcomeRoute } from "./pages/home/cloud/welcomeRoute";
import { homeIndexRoute, homeLayoutRoute } from "./pages/home/homeLayoutRoute";

import { businessCriticalAssetsRoute } from "./pages/inventory/BusinessCriticalAssets/businessCriticalAssetsRoute";
import {
  inventoryApplicationAssetsRoute,
  inventoryApplicationGroupedAssetsRoute,
  inventoryApplicationsRoute,
} from "./pages/inventory/applications/applicationsRoute";
import {
  assetExplorerRoute,
  assetInventoryAssetExplorerRoute,
  assetInventoryCloudAccountAssetExplorerRoute,
  assetInventoryCloudAccountServiceNameAssetExplorerRoute,
  assetInventoryCloudTypeAssetExplorerRoute,
  assetInventoryCloudTypeServiceNameRouteAssetExplorerRoute,
  assetInventoryRegionAssetExplorerRoute,
  assetInventoryServiceNameAssetExplorerRoute,
} from "./pages/inventory/assetExplorer/assetExplorerRoute";
import { assetInventoryCloudAccountServiceNameRoute } from "./pages/inventory/assetInventory/CloudAccount/ServiceName/assetInventoryCloudAccountServiceNameRoute";
import { assetInventoryCloudAccountRoute } from "./pages/inventory/assetInventory/CloudAccount/assetInventoryCloudAccountRoute";
import { assetInventoryCloudTypeServiceNameRoute } from "./pages/inventory/assetInventory/CloudType/ServiceName/assetInventoryCloudTypeServiceNameRoute";
import { assetInventoryCloudTypeRoute } from "./pages/inventory/assetInventory/CloudType/assetInventoryCloudTypeRoute";
import { assetInventoryRegionRoute } from "./pages/inventory/assetInventory/Region/assetInventoryRegionRoute";
import { assetInventoryServiceNameRoute } from "./pages/inventory/assetInventory/ServiceName/assetInventoryServiceNameRoute";
import { assetInventoryRoute } from "./pages/inventory/assetInventory/assetInventoryRoute";
import {
  casInventoryNestedRoute,
  casInventoryRoute,
} from "./pages/inventory/cas/casInventoryRoute";
import { containerImagesRoute } from "./pages/inventory/computeWorkload/ContainerImages/containerImagesRoute";
import { hostsRoute } from "./pages/inventory/computeWorkload/Hosts/hostsRoute";
import { computeWorkloadRoute } from "./pages/inventory/computeWorkload/computeWorkloadOverview/computeWorkloadRoute";
import { dataInventoryLayoutRoute } from "./pages/inventory/data-inventory/cloud/dataInventoryLayoutRoute";
import { objectLayoutRoute } from "./pages/inventory/data-inventory/object/objectLayoutRoute";
import { resourceLayoutRoute } from "./pages/inventory/data-inventory/resource/resourceLayoutRoute";
import { serviceLayoutRoute } from "./pages/inventory/data-inventory/service/serviceLayoutRoute";
import {
  inventoryCatchAllRoute,
  inventoryLayoutRoute,
} from "./pages/inventory/inventoryLayoutRoute";
import {
  unmanagedAssetByResourceTypeRoute,
  unmanagedAssetListRoute,
  unmanagedAssetRoute,
} from "./pages/inventory/unmanagedAsset/unmanagedAssetRoute";
import { apiEndpointsRoute } from "./pages/inventory/waas/ApiEndpointsRoute";
import { backgroundJobsRoute } from "./pages/investigate/backgroundJobs/backgroundJobsRoute";
import {
  investigateIndexRoute,
  investigateLayoutRoute,
} from "./pages/investigate/investigateLayoutRoute";
import { queryLibraryRoute } from "./pages/investigate/queryLibrary/queryLibraryRoute";
import { searchRoute } from "./pages/investigate/searchResults/searchResultsRoute";
import { loginRoute } from "./pages/login/loginRoute";
import { loginSSORoute } from "./pages/loginSSO/loginSSORoute";
import { logoutRoute } from "./pages/logout/logoutRoute";
import { alertsReportsRoute } from "./pages/reports/alerts/alertsReportsRoute";
import { cloudSecurityReportRoute } from "./pages/reports/cloudSecurityReport/cloudSecurityReportRoute";
import {
  complianceReportAutoDownloadRoute,
  complianceReportsRoute,
} from "./pages/reports/compliance/complianceReportsRoute";
import { reportsLayoutRoute } from "./pages/reports/reportsLayoutRoute";
import { requestPasswordResetRoute } from "./pages/requestPasswordReset/requestPasswordResetRoute";
import { resetPasswordRoute } from "./pages/resetPassword/resetPasswordRoute";
import { accessControlRoute } from "./pages/settings/accessControl/accessControlRoute";
import { accessKeysRoute } from "./pages/settings/accessControl/tabs/accessKeys/accessKeysRoute";
import { permissionGroupsRoute } from "./pages/settings/accessControl/tabs/permissionGroups/permissionGroupsRoute";
import { accountGroupModalInRolesRoute } from "./pages/settings/accessControl/tabs/roles/accountGroupModalInRolesRoute";
import { rolesRoute } from "./pages/settings/accessControl/tabs/roles/rolesRoute";
import { ssoRoute } from "./pages/settings/accessControl/tabs/sso/ssoRoute";
import { supportAccessRoute } from "./pages/settings/accessControl/tabs/supportAccess/supportAccessRoute";
import { usersRoute } from "./pages/settings/accessControl/tabs/users/usersRoute";
import { accountGroupModalRoute } from "./pages/settings/accountGroups/Modal/accountGroupModalRoute";
import { accountGroupsRoute } from "./pages/settings/accountGroups/settingsAccountGroupsRoute";
import { alertsThresholdsRoute } from "./pages/settings/anomalies/alertsThresholds/alertsThresholdsRoute";
import { anomaliesLayoutRoute } from "./pages/settings/anomalies/anomaliesLayoutRoute";
import { trustedListRoute } from "./pages/settings/anomalies/trustedList/trustedListRoute";
import { settingsApplicationsDefinitionRoute } from "./pages/settings/applicationsDefinition/settingsApplicationsDefinitionRoute";
import { auditLogsRoute } from "./pages/settings/auditLogs/auditLogsRoute";
import {
  settingsCICDRunsNestedRoute,
  settingsCICDRunsRoute,
} from "./pages/settings/casProviders/settingsCICDRunsRoute";
import {
  settingsCICDSystemsNestedRoute,
  settingsCICDSystemsRoute,
} from "./pages/settings/casProviders/settingsCICDSystemsRoute";
import {
  settingsIntegrationsCatalogNestedRoute,
  settingsIntegrationsCatalogRoute,
} from "./pages/settings/casProviders/settingsIntegrationsCatalogRoute";
import {
  settingsPrivateRegistriesNestedRoute,
  settingsPrivateRegistriesRoute,
} from "./pages/settings/casProviders/settingsPrivateRegistriesRoute";
import {
  settingsThirdPartyNestedRoute,
  settingsThirdPartyRoute,
} from "./pages/settings/casProviders/settingsThirdPartyRoute";
import {
  settingsVCSNestedRoute,
  settingsVCSRoute,
} from "./pages/settings/casProviders/settingsVCSRoute";
import { settingsCloudAccountsEditModalRoute } from "./pages/settings/cloudAccounts/CloudOnboarding/Onboarding/settingsCloudAccountsEditModalRoute";
import { settingsCloudAccountsSidecarRoute } from "./pages/settings/cloudAccounts/Sidecar/settingsCloudAccountsSidecarRoute";
import {
  casProvidersNestedRoutes,
  settingsCloudAccountsRoute,
} from "./pages/settings/cloudAccounts/settingsCloudAccountsRoute";
import { collectionWizardEditRoute } from "./pages/settings/collections/CollectionsWizard/collectionWizardEditRoute";
import { collectionsRoute } from "./pages/settings/collections/settingsCollectionsRoute";
import { dataPatternsLayoutRoute } from "./pages/settings/dataSettings/dataPatterns/dataPatternsLayoutRoute";
import { dataProfilesLayoutRoute } from "./pages/settings/dataSettings/dataProfiles/dataProfilesLayoutRoute";
import { dataSettingsLayoutRoute } from "./pages/settings/dataSettings/dataSettingsLayoutRoute";
import { scanSettingsLayoutRoute } from "./pages/settings/dataSettings/scanSettings/scanSettingsLayoutRoute";
import { snippetMaskingLayoutRoute } from "./pages/settings/dataSettings/snippetMasking/snippetMaskingLayoutRoute";
import { enterpriseSettingsRoute } from "./pages/settings/enterprise/enterpriseRoute";
import { integrationAndNotificationRoute } from "./pages/settings/integrationAndNotification/integrationAndNotificationRoute";
import { integrationsRoute } from "./pages/settings/integrationAndNotification/integrations/integrationsRoute";
import { notificationsRoute } from "./pages/settings/integrationAndNotification/notifications/notificationsRoute";
import { justInTimeRoute } from "./pages/settings/jit/justInTimeRoute";
import {
  myTasksByRequestIdRoute,
  myTasksRoute,
  requestByRequestIdRoute,
  requestsRoute,
} from "./pages/settings/jit/requests/routes";
import { roleBindingsRoute } from "./pages/settings/jit/roles/routes/roleBindingsRoute";
import { activeSessionsRoute } from "./pages/settings/jit/sessions/routes/activeSessionsRoute";
import { jitSettingsRoute } from "./pages/settings/jit/settings/routes/jitSettingsRoute";
import { licensingOverviewRoute } from "./pages/settings/licensing/components/Overview/licensingOverviewRoute";
import { licensingCreditAllocationRoute } from "./pages/settings/licensing/creditAllocation/creditAllocationRoute";
import { licensingRoute } from "./pages/settings/licensing/settingsLicensingRoute";
import { settingsK8sSatelliteRoute } from "./pages/settings/providers/k8sSatellite/settingsK8sSatelliteRoute";
import { settingsProvidersRoute } from "./pages/settings/providers/settingsProvidersRoute";
import { resourceListsRoute } from "./pages/settings/resourceLists/resourceListsRoute";
import {
  casSettingsNestedRoute,
  casSettingsRoute,
  settingsCatchAllRoute,
  settingsLayoutRoute,
} from "./pages/settings/settingsLayoutRoute";
import { trustedIpAddressesRoute } from "./pages/settings/trustedIpAddresses/trustedIpAddressesRoute";
import { trustedIpAlertRoute } from "./pages/settings/trustedIpAddresses/trustedIpAlert/trustedIpAlertRoute";
import { trustedIpLoginRoute } from "./pages/settings/trustedIpAddresses/trustedIpLogin/trustedIpLoginRoute";
import { ssoErrorRoute } from "./pages/ssoError/ssoErrorRoute";
import { versionRoute } from "./pages/version/versionRoute";

export const routeTree = rootRoute.addChildren([
  logoutRoute,
  versionRoute,
  casProjectsRedirectRoute,
  publicRootRoute.addChildren([
    loginRoute,
    loginSSORoute,
    requestPasswordResetRoute,
    resetPasswordRoute,
    ssoErrorRoute,
  ]),
  privateRootRoute.addChildren([
    commandCenterRoute,
    templateRoute,
    ftuExperienceRoute,
    fttvExperienceRoute,
    homeLayoutRoute.addChildren([
      homeIndexRoute,
      homeCloudLayoutRoute.addChildren([
        homeCloudIndexRoute,
        adoptionAdvisorRoute,
        welcomeRoute,
      ]),
      homeAppSecLayoutRoute.addChildren([
        homeAppSecIndexRoute,
        casProjectsRoute.addChildren([casProjectsNestedRoute]),
        casRepositoriesRoute.addChildren([casRepositoriesNestedRoute]),
        casSbomRoute.addChildren([casSbomNestedRoute]),
        casTechnologiesRoute.addChildren([casTechnologiesNestedRoute]),
        casCicdRisksRoute.addChildren([casCicdRisksNestedRoute]),
      ]),
      computeLayoutRoute,
      homeDataLayoutRoute.addChildren([
        homeDataOverviewRoute,
        homeDataOverviewNestedRoutes,
        homeDataInventoryRoute,
        homeDataInventoryNestedRoutes,
        homeDataAiInventoryRoute,
        homeDataFilesRoute,
        homeDataFindingsRoute,
        homeDataFindingsNestedRoutes,
        homeDataRisksRoute,
        homeDataRisksNestedRoutes,
        homeDataComplianceRoute,
        homeDataPoliciesRoute,
        homeDataAlertsRoute,
        homeDataAlertsNestedRoutes,
        homeDataReportsRoute,
        homeDataSettingsRoute,
        homeDataSettingsNestedRoutes,
      ]),
    ]),
    dashboardsLayoutRoute.addChildren([
      dashboardsRedirectRoute,
      dashboardRoute.addChildren([
        assetListSidecarRoute,
        vulnerabilitiesSidecarRoute,
      ]),
    ]),
    investigateLayoutRoute.addChildren([
      investigateIndexRoute,
      queryLibraryRoute,
      backgroundJobsRoute,
      searchRoute,
    ]),
    inventoryLayoutRoute.addChildren([
      assetExplorerRoute,
      dataInventoryLayoutRoute,
      serviceLayoutRoute,
      resourceLayoutRoute,
      objectLayoutRoute,
      assetInventoryRoute,
      assetInventoryAssetExplorerRoute,
      assetInventoryCloudAccountServiceNameRoute,
      assetInventoryCloudAccountServiceNameAssetExplorerRoute,
      assetInventoryCloudAccountRoute,
      assetInventoryCloudAccountAssetExplorerRoute,
      assetInventoryRegionRoute,
      assetInventoryRegionAssetExplorerRoute,
      assetInventoryServiceNameRoute,
      assetInventoryServiceNameAssetExplorerRoute,
      assetInventoryCloudTypeServiceNameRoute,
      assetInventoryCloudTypeServiceNameRouteAssetExplorerRoute,
      assetInventoryCloudTypeRoute,
      assetInventoryCloudTypeAssetExplorerRoute,
      apiEndpointsRoute,
      businessCriticalAssetsRoute,
      casInventoryRoute.addChildren([casInventoryNestedRoute]),
      computeWorkloadRoute,
      hostsRoute,
      containerImagesRoute,
      unmanagedAssetRoute.addChildren([
        unmanagedAssetByResourceTypeRoute.addChildren([
          unmanagedAssetListRoute,
        ]),
      ]),
      inventoryApplicationsRoute.addChildren([
        inventoryApplicationGroupedAssetsRoute.addChildren([
          inventoryApplicationAssetsRoute,
        ]),
      ]),
      inventoryCatchAllRoute,
    ]),
    complianceLayoutRoute.addChildren([
      complianceStandardsLayoutRoute.addChildren([
        complianceStandardsRoute,
        complianceRequirementsLayoutRoute.addChildren([
          complianceRequirementsRoute,
          complianceSectionsRoute,
        ]),
      ]),
      complianceCatchAllRoute,
    ]),
    governanceRoute.addChildren([
      policyWizardRoute,
      editPolicyWizardBackwardCompatibleRoute,
    ]),
    actionPlansLayoutRoute.addChildren([
      actionPlansIndexRoute,
      actionPlansOverviewRoute,
    ]),
    alertsLayoutRoute.addChildren([
      alertsIndexRoute,
      alertsOverviewRoute.addChildren([policyWizardInAlertsRoute]),
      alertsL2TableRoute,
      alertsCatchAllRoute,
    ]),
    reportsLayoutRoute.addChildren([
      alertsReportsRoute,
      complianceReportsRoute.addChildren([complianceReportAutoDownloadRoute]),
      cloudSecurityReportRoute,
    ]),
    settingsLayoutRoute.addChildren([
      casSettingsRoute.addChildren([casSettingsNestedRoute]),
      settingsProvidersRoute.addChildren([
        settingsCloudAccountsRoute.addChildren([
          settingsCloudAccountsSidecarRoute,
          settingsCloudAccountsEditModalRoute,
          casProvidersNestedRoutes,
        ]),
        settingsVCSRoute.addChildren([settingsVCSNestedRoute]),
        settingsCICDRunsRoute.addChildren([settingsCICDRunsNestedRoute]),
        settingsThirdPartyRoute.addChildren([settingsThirdPartyNestedRoute]),
        settingsCICDSystemsRoute.addChildren([settingsCICDSystemsNestedRoute]),
        settingsPrivateRegistriesRoute.addChildren([
          settingsPrivateRegistriesNestedRoute,
        ]),
        settingsIntegrationsCatalogRoute.addChildren([
          settingsIntegrationsCatalogNestedRoute,
        ]),
        settingsK8sSatelliteRoute,
      ]),
      auditLogsRoute,
      enterpriseSettingsRoute,
      accountGroupsRoute.addChildren([accountGroupModalRoute]),
      resourceListsRoute,
      collectionsRoute.addChildren([collectionWizardEditRoute]),
      integrationAndNotificationRoute.addChildren([
        integrationsRoute,
        notificationsRoute,
      ]),
      licensingRoute.addChildren([
        licensingOverviewRoute,
        licensingCreditAllocationRoute,
      ]),
      justInTimeRoute.addChildren([
        requestsRoute.addChildren([requestByRequestIdRoute]),
        myTasksRoute.addChildren([myTasksByRequestIdRoute]),
        activeSessionsRoute,
        roleBindingsRoute,
        jitSettingsRoute,
      ]),
      anomaliesLayoutRoute.addChildren([
        alertsThresholdsRoute,
        trustedListRoute,
      ]),
      trustedIpAddressesRoute.addChildren([
        trustedIpAlertRoute,
        trustedIpLoginRoute,
      ]),
      accessControlRoute.addChildren([
        accessKeysRoute,
        rolesRoute.addChildren([accountGroupModalInRolesRoute]),
        usersRoute,
        supportAccessRoute,
        permissionGroupsRoute,
        ssoRoute,
      ]),
      dataSettingsLayoutRoute.addChildren([
        scanSettingsLayoutRoute,
        snippetMaskingLayoutRoute,
        dataProfilesLayoutRoute,
        dataPatternsLayoutRoute,
      ]),
      settingsApplicationsDefinitionRoute,
      settingsCatchAllRoute,
    ]),
    catchAllRoute,
  ]),
]);

/**
 * Set the component for the private route.
 * It can't be set in the router package due to the web of dependencies it has
 */
const Layout = lazy(() => import("./Layout"));
privateRootRoute.options.component = Layout;
privateRootRoute.options.loader = async ({ context }) => {
  // the preferences API is slow as molasses so prefetching it here.
  context?.queryClient.ensureQueryData({
    queryKey: preferencesKeys.all,
    queryFn: getPreferences,
  });
};
privateRootRoute.options.beforeLoad = ({
  location: { pathname, search, hash },
  navigate,
}) => {
  const { isAuthenticated, actions } = useTokenStore.getState();

  if (!isAuthenticated()) {
    localStorage.setItem(
      signinRedirectLocationStorageKey,
      JSON.stringify({
        pathname: pathname,
        search: search,
        hash: hash,
      }),
    );
    actions.removeToken();

    navigate({
      to: "/logout",
    });
  } else {
    const legacyFilters = hashParams();

    if (!Object.keys(legacyFilters).length) return;
    if (Object.keys(legacyFilters).includes("#")) return;
    // @ts-expect-error search doesn't have a schema yet at this point
    if (Object.keys(search?.filters ?? {}).length > 0) return;

    const newHash = Object.entries(legacyFilters).reduce<
      Record<string, unknown>
    >((previous, current) => {
      const [key, value] = current;
      if (
        key === "alert.status" ||
        key === "policy.name" ||
        key === "policy.type" ||
        key === "policy.subtype"
      ) {
        previous[key] = Array.isArray(value) ? value : [value];
      } else if (key === "policy.enabled") {
        previous[key] = Array.isArray(value) ? value[0] : value;
      } else {
        previous[key] = value;
      }
      return previous;
    }, {});

    navigate({
      to: window.location.pathname,
      replace: true,
      search: { ...search, filters: newHash },
      hash: "#",
    });
  }
};

export const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultPendingComponent: () => (
    <div className="flex grow items-center justify-center text-5xl">
      <LoadingIcon />
    </div>
  ),
  defaultStaleTime: Infinity,
  defaultNotFoundComponent: CatchAll,
  context: { queryClient },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
