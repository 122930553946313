import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { accessControlRoute } from "../../accessControlRoute";

const SupportAccess = lazy(() =>
  import("./SupportAccess").then((res) => ({
    default: res.SupportAccess,
  })),
);

export const supportAccessRoute = new Route({
  getParentRoute: () => accessControlRoute,
  path: "support-access",
  component: SupportAccess,
});
